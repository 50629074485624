/**
 *
 * styled LoadingContainer
 *
 */
import { FlexRowCenter } from '@styles/global-styles';
import styled from 'styled-components';

export const LoadingContainerWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: transparent;
  /* backdrop-filter: blur(10px); */
  ${FlexRowCenter};
  .ant-spin {
    color: #d1005c;
    &-container {
      width: 100%;
      height: 100%;
    }
    &-nested-loading {
      width: 100%;
      height: 100%;
      > div > .ant-spin {
        width: 100%;
        height: 100%;
        max-height: 100%;
      }
    }
  }
`;
