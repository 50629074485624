import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { selectProvinceSelected } from '@containers/App/selectors';
import { useSelector } from 'react-redux';
import LoadingContainer from '@components/LoadingContainer';

function PublicContainer() {
  const router = useRouter();

  const provinceSelected = useSelector(selectProvinceSelected);

  useEffect(() => {
    if (provinceSelected) router.replace(`/${provinceSelected}/discover`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provinceSelected]);

  return <LoadingContainer loading></LoadingContainer>;
}

export default PublicContainer;
